import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivacyPolicy } from "./privacy-policy/PrivacyPolicy";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";

import { HomeScreen } from "./home/home";
import { LoginScreen } from "./login/login";
import { SignupScreen } from "./signup/signup";
import { useEffect } from "react";
import { LearningScreen } from "./classroom/learning";
import TestScreen from "./ExamModule/TestModule";
import { CoursesScreen } from "./courses/courses";
import { PDFViewer } from "./PdfView/pdf-viewer";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { io } from "socket.io-client";
import { RestrictedAccess } from "./restricted/restricted";
import { Registration } from "./registration/user-register";
import ReactGA from "react-ga";
import { CourseDetails } from "./courses/course-details";
import PaymentStatus from "./courses/course-payment-status";
import { ContentViewer } from "./PdfView/content-viewer";
import TestimonialComponent from "./testimonial/testimonial";
import CourseComponent from "./courses/CourseNewComponent";
import CareersInOBG from "./CareersInOBG/CareersInOBG";
import Toppers from "./Toppers/Toppers";
import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";
import Tnc from "./Tnc/tnc";
import RefundPolicy from "./RefundPolicy/RefundPolicy";
import StartExam from "./ExamModule/StartExam";
import SettingsScreen from "./Settings/settings";
import AccountsScreen from "./Accounts/accounts";
import NotificationScreen from "./Notifications/notifications";
import Classroom from "./classroom/classroom";
import { Shipping } from "./registration/shipping";
import { setUserProfile } from "./redux/loginSlice";
import TestSubmittedAnalyticsScreen from "./ExamModule/TestSubmittedAnalyticsScreen";
import PreviousTestScreen from "./ExamModule/previousRecords/PreviousTestScreen";
import CustomPdfViewer from "./PdfView/custom-pdf-viewer";
import TestSeries from "./test-series/TestSeries"
import { ShortCourseScreen } from "./courses/short_course";
ReactGA.initialize("G-TNEYYEXF80");
// require("dotenv").config();
export const socket = io("https://services.reproneetlearning.com", {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10001,
  transports: ["websocket"],
});

// export default socket;
const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      localStorage.getItem("userInfo") !== null &&
      localStorage !== undefined
    ) {
      dispatch(setUserProfile(JSON.parse(localStorage.getItem("userInfo"))));
    }
    socket.on("forceClose", () => {
      window.location.href = "https://www.reproneetlearning.com/restricted";
    });

    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const firebaseConfig = {
    apiKey: "AIzaSyBMraYIiAJnWf5iPNOicgvUQ1OxTR5zKBM",
    authDomain: "edulabz-c0a8f.firebaseapp.com",
    databaseURL: "https://edulabz-c0a8f-default-rtdb.firebaseio.com",
    projectId: "edulabz-c0a8f",
    storageBucket: "edulabz-c0a8f.appspot.com",
    messagingSenderId: "711487760543",
    appId: "1:711487760543:web:68cea5b0e39a9c209bdaaa",
    measurementId: "G-TNEYYEXF80",
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/courses" element={<CoursesScreen />} />
        <Route path="/shortCourses" element={<ShortCourseScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/classroom" element={<Classroom />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/test" element={<TestScreen />} />
        <Route path="/start-test" element={<StartExam />} />
        <Route path="/courseInfo" element={<CourseDetails />} />
        <Route path="/course/:courseId" element={<CourseComponent />} />
        <Route path="/payment-verification" element={<PaymentStatus />} />
        <Route path="/testimonial" element={<TestimonialComponent />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/careers" element={<CareersInOBG />} />
        <Route path="/toppers" element={<Toppers />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/toppers" element={<Toppers />} />
        <Route path="/terms" element={<Tnc />} />
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/accounts" element={<AccountsScreen />} />
        <Route path="/notification" element={<NotificationScreen />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/test-series" element={<TestSeries />} />
        <Route
          path="/test-analytics"
          element={<TestSubmittedAnalyticsScreen />}
        />
        <Route
          path="/previous-test-record"
          element={<PreviousTestScreen />}
        />
        <Route
          path="/course-viewer/:url/:deviceType"
          element={<ContentViewer />}
        />
         <Route
          path="/content-viewer/:url/:deviceType"
          element={<CustomPdfViewer />}
        />
        {/* <Route
          path="/content-viewer/:url/:deviceType"
          element={<PDFViewer />}
        /> */}
        <Route path="/learning/:courseId" element={<LearningScreen />} />
        <Route path="/restricted" element={<RestrictedAccess />} />
        <Route
          exact={true}
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
}

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

export default App;
