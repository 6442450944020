import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppConstant } from "../.environments";

const initialState = {
  courses: [],
  loading: false,
  error: "",
};

const fetchShortCourses = createAsyncThunk("courses/fetchShortCourses", () => {
  return axios
    .get(`${AppConstant.BASE_URL}/v1/api/courses/getallsortcourses?size=1000&page=0&isSort=true`)
    .then((res) => {
      return res.data.responseData.courseResponse;
    });
});

const shortCourseSlice = createSlice({
  name: "shortCourseReducer",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchShortCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchShortCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.courses = action.payload;
      state.error = "";
    });
    builder.addCase(fetchShortCourses.rejected, (state, action) => {
      state.loading = false;
      state.courses = [];
      state.error = action.error.message;
    });
  },
});

export default shortCourseSlice.reducer;
export { fetchShortCourses };
