import React from 'react'

import { Toolbar } from "../toolbar/toolbar";

import { AiFillStar } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

function ContactUs() {
    return (

        <div>

            <Toolbar />

            <div className="w-full bg-gradient-to-r from-orange-300 to-pink-400  h-[12rem] flex justify-center items-center">
                <p className="text-white text-5xl font-bold"> Contact Us</p>
            </div>

            <div className='flex flex-col items-center md:justify-center'>

                <div className="w-full lg:w-[60%] mt-10 bg-gray-100 md:px-10 px-5">
                    <p className="my-3 text-base md:text-lg">If you have any issue related to course/content, Please write us at <a href="mailto:reproneet@gmail.com" className='font-bold text-red-600 mx-0'>reproneet@gmail.com</a>
                    </p>
                    <p className="my-3 text-base md:text-lg">In case of any technical issue in Mobile Application/Website Please  write us at <a href="mailto:support@reproneetlearning.com" className='font-bold text-red-600 mx-0'>support@reproneetlearning.com</a>
                    </p>
                    <p className="my-3 text-base md:text-lg">In case of any technical issue in Mobile Application/Website Please  call us at <a href="tel:+919430511644" className='font-bold text-red-600 mx-0'>+91 9430511644</a> between 3 PM to 10 PM Everyday.
                    </p>
                    <p className="my-3 text-base md:text-lg">Address : D/O Himanshu Sekhar Singh, Aaranya, Adarsh Vihar Colony, Rukanpura, Near Suvidha Utsav Parishar, P.S - Rupaspur, Rukanpura, Patna, BIHAR - 800014
                    </p>
                </div>

                <div className='w-full lg:w-[60%] mb-20 bg-gray-100 md:px-10 px-5'>

                    <div className='md:my-10'>
                        <p className='text-2xl md:text-5xl font-bold'>Send Us A Message!</p>

                        <div className=' my-5 md:mt-20'>
                            <div className=' '>
                                <div className='text-lg flex items-center mb-2 ms-2'>
                                    Name <sup><AiFillStar className='text-[10px] text-red-500 ' /></sup><br />

                                </div>
                                <div className='flex '>
                                    <input type="text" name="" placeholder='First Name...' id="" className='py-3 px-2  w-[160px] md:w-[34%] mx-2' />
                                    <input type="text" name="" placeholder='Last Name...' id="" className=' px-2 py-3 w-[160px] md:w-[34%] ' />
                                </div>
                            </div>
                        </div>

                        <div className=' my-5'>
                            <div className=''>
                                <div className='text-lg flex items-center mb-2 ms-2'>
                                    Email <sup><AiFillStar className='text-[10px] text-red-500 ' /></sup><br />

                                </div>
                                <div className='flex mx-2'>

                                    <input type="email" name="" placeholder='Enter Email Id...' id="" className=' px-2 py-3 w-[325px] md:w-[70%] ' />
                                </div>
                            </div>
                        </div>

                        <div className=' my-5'>
                            <div className=''>
                                <div className='text-lg flex items-center mb-2 ms-2'>
                                    Mobile Number <sup><AiFillStar className='text-[10px] text-red-500 ' /></sup><br />

                                </div>
                                <div className='flex mx-2'>

                                    <input type="mobile" name="" placeholder='Enter Mobile Number...' id="" className=' px-2 py-3 w-[325px] md:w-[70%] ' />
                                </div>
                            </div>
                        </div>

                        <div className=' my-5'>
                            <div className=''>
                                <div className='text-lg flex items-center mb-2 ms-2'>
                                    Comment or Message <sup><AiFillStar className='text-[10px] text-red-500 ' /></sup><br />

                                </div>
                                <div className='flex mx-2'>

                                    <textarea name="" id="" cols="30" rows="3" placeholder='Enter Message...' className=' px-2 py-3 w-[325px] md:w-[70%] ' ></textarea>
                                </div>
                            </div>
                        </div>


                        <div className=' my-5'>
                            <div className=''>
                                <div className='text-lg flex items-center mb-2 '>
                                    <Link to="" className='border-2 border-gray-400 p-3  px-5 font-bold'>Submit</Link>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>

            </div>

            <div>
                <Footer />
            </div>

        </div>

    )
}

export default ContactUs
